<template>
  <section>
      <!-- MENU -->
      <div class="menu">
        <h1 class="menu__header"><strong>Design</strong> resilience curriculum</h1>
        <div class="carousel">
          <hooper
            ref="carousel_01"
            :settings="hooperSettings"
            class="mb-4"
          >
            <slide
              v-for="menuItem in $store.state.personalData.design"
              :key="menuItem.category"
            >
              <router-link
                :to="menuItem.route" append
                class="menu-slide"
              >
                <h3>{{menuItem.name}}</h3>
                <div class="menu-slide__category">
                  {{menuItem.category}}
                </div>
              </router-link>
            </slide>
            <hooper-navigation slot="hooper-addons">
              <img
                slot="hooper-prev"
                src="@/assets/img/_ui/svg/carousel-back.svg"
                alt="previous"
                srcset=""
              >
              <img
                slot="hooper-next"
                src="@/assets/img/_ui/svg/carousel-next.svg"
                alt="next"
                srcset=""
              >
            </hooper-navigation>
          </hooper>
        </div>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation
  // Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation
    // HooperPagination
  },
  data () {
    return {
      hooperSettings: {
        itemsToShow: 1,
        trimWhiteSpace: true,
        centerMode: false,
        wheelControl: false,
        breakpoints: {
          1024: {
            itemsToShow: 3
          }
        }
      }
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
